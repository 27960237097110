import { inject, Injectable } from '@angular/core';
import { UserMenu, UserMenuItem } from '@domain/models/auth/user-menu';
import { SidebarRepository } from '@domain/models/sidebar/gateway/sidebar-repository';
import { IdentityServiceImpl } from '@infra/identity/identity-service';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarServiceImpl extends SidebarRepository {
  /**
   * Subject que emite el estado de menú (activo o inactivo)
   */
  private status: Subject<boolean> = new Subject<boolean>();

  /**
   * Subject que emite el menú cargado
   */
  private menuStream: Subject<UserMenu> = new Subject<UserMenu>();

  seusService = inject(IdentityServiceImpl);

  /**
   * Constructor
   *
   */
  constructor() {
    super();
    setTimeout(() => {
      this.loadMenu();
    }, 300);
  }

  /**
   * Invocar para emitir un nuevo estado del menú
   *
   * @param isActive true si esta activo, false en caso contrario
   */
  setStatus(isActive: boolean): void {
    this.status.next(isActive);
  }

  /**
   * Invocar para emitir un nuevo menú
   *
   * @param menu UserMenu
   */
  setMenu(menu: UserMenu): void {
    this.menuStream.next(menu);
  }

  /**
   * Permite suscriberse al estado del menú
   *
   * @param observer observador: callback
   */
  onStatusChanged(observer: (isActive: boolean) => void): Subscription {
    return this.status.subscribe(observer);
  }

  /**
   * Invoca el identity service para obtener el menú
   */
  loadMenu(): void {
    this.seusService.getUserMenu().then((userMenu: UserMenu) => {
      if (!userMenu) {
        return;
      }
      this.inactivateAllItems(userMenu.menuItems);
      this.setMenu(userMenu);
    });
  }

  /**
   * Desactiva todos los items del menú de forma recursiva
   *
   * @param items items del menú que se van a desactivar
   */
  inactivateAllItems(items: Array<UserMenuItem>): void {
    items.forEach((value) => {
      value.active = false;
      if (value.children && value.children.length > 0) {
        this.inactivateAllItems(value.children);
      }
    });
  }
}
