import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserMenuItem } from '@domain/models/auth/user-menu';
import { SidebarSubItemComponent } from '../sidebar-sub-item/sidebar-sub-item.component';
import { LinkDirective } from '@view/shared/atoms/fields';
import { IconDirective } from '@view/shared/atoms/icons';

@Component({
  selector: 'app-sidebar-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LinkDirective,
    IconDirective,
    SidebarSubItemComponent
  ],
  template: `
    <li
      class="cursor-pointer rk-sidebar-item"
      [ngClass]="{ gt_sidebar_item_active: item.active }"
    >
      <hr class="rk-custom-hr" />
      <a
        [routerLink]="
          item.children && item.children.length > 0
            ? null
            : '/'.concat(item.contextPath)
        "
        appLink
        class="rk-sidebar-item-link"
        (click)="toggleSubmenu(item)"
      >
        <i appIcon [icon]="item.image"></i>
        <span
          class="rk-text-bar rk-headline--sub-one rk-text-color--white"
          [ngClass]="{ isExpanded: isExpanded }"
          >{{ item.name }}</span
        >
      </a>
      <app-sidebar-sub-item
        *ngIf="item.children && item.children.length > 0"
        [basePath]="item.contextPath"
        [visible]="item.active"
        [itemsMenu]="item.children"
        [paddingLeft]="20"
      >
      </app-sidebar-sub-item>
    </li>
  `,
  styles: []
})
export class SidebarItemComponent implements OnChanges {
  @Input() item!: UserMenuItem;
  @Input() isExpanded!: boolean;
  @Output() expanded = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded && changes.isExpanded.currentValue === false) {
      this.item.active = false;
    }
  }

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   *
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: UserMenuItem): void {
    // desactivamos todos los menus
    menu.active = !menu.active;
    if (!this.isExpanded) {
      this.expanded.emit(true);
    }
  }
}
