import { CartRepository } from '@domain/models/cart/gateway/cart-repository';
import { AddLegoToCartUseCase } from '@domain/usecases/cart/add-task-to-cart';
import { DeleteAllTaskFromCartUseCase } from '@domain/usecases/cart/delete-all-tasks-from-cart';
import { DeleteTaskFromCartUseCase } from '@domain/usecases/cart/delete-task-from-cart';
import { GetAllFromCartUseCase } from '@domain/usecases/cart/get-all-from-cart';
import { CartRepositoryImpl } from '@infra/cart/cart-repository-impl';

const getCartRepositoryProvider = {
  provide: CartRepository,
  useClass: CartRepositoryImpl
};

const AddLegoToCartUseCaseFactory = (cartRepository: CartRepository) => {
  return new AddLegoToCartUseCase(cartRepository);
};

const AddLegoToCartUseCaseProvider = {
  provide: AddLegoToCartUseCase,
  useFactory: AddLegoToCartUseCaseFactory,
  deps: [CartRepository]
};

const GetAllFromCartUseCaseFactory = (cartRepository: CartRepository) => {
  return new GetAllFromCartUseCase(cartRepository);
};

const GetAllFromCartUseCaseProvider = {
  provide: GetAllFromCartUseCase,
  useFactory: GetAllFromCartUseCaseFactory,
  deps: [CartRepository]
};

const DeleteLegoFromCartUseCaseFactory = (cartRepository: CartRepository) => {
  return new DeleteTaskFromCartUseCase(cartRepository);
};

const DeleteLegoFromCartUseCaseProvider = {
  provide: DeleteTaskFromCartUseCase,
  useFactory: DeleteLegoFromCartUseCaseFactory,
  deps: [CartRepository]
};

const DeleteAllTaskFromCartUseCaseFactory = (
  cartRepository: CartRepository
) => {
  return new DeleteAllTaskFromCartUseCase(cartRepository);
};

const DeleteAllTaskFromCartUseCaseProvider = {
  provide: DeleteAllTaskFromCartUseCase,
  useFactory: DeleteAllTaskFromCartUseCaseFactory,
  deps: [CartRepository]
};

export const getCartDi = [
  getCartRepositoryProvider,
  AddLegoToCartUseCaseProvider,
  GetAllFromCartUseCaseProvider,
  DeleteLegoFromCartUseCaseProvider,
  DeleteAllTaskFromCartUseCaseProvider
];
