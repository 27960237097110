import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { CartService } from '@domain/services/cart.service';
import { AddLegoToCartUseCase as AddTaskToCartUseCase } from '@domain/usecases/cart/add-task-to-cart';
import { GetAllFromCartUseCase } from '@domain/usecases/cart/get-all-from-cart';
import { DeleteTaskFromCartUseCase as DeleteTaskFromCartUseCase } from '@domain/usecases/cart/delete-task-from-cart';
import { ExecutionContractFactory } from '@domain/models/execution/execution';
import { Subject, first, takeUntil } from 'rxjs';
import { DeleteAllTaskFromCartUseCase } from '@domain/usecases/cart/delete-all-tasks-from-cart';
import { WizardVisualizerService } from '@view/executions/pages/wizard/wizard-visualizer.service';

@Component({
  selector: 'app-cart-org',
  standalone: true,
  imports: [CommonModule, AvatarModule, BadgeModule],
  template: `
    <div class="flex pt-3 mr-4">
      <p-avatar
        image="assets/images/rocket.svg"
        [styleClass]="getStyleClass()"
        size="large"
        severity="danger"
        pBadge
        [value]="cartService.getCartCount().toString()"
        (click)="onCartClick()"
      ></p-avatar>
    </div>
  `,
  styles: []
})
export class CartOrgComponent implements OnInit, OnDestroy {
  private readonly addToCartUseCase = inject(AddTaskToCartUseCase);
  private readonly getFromCartUseCase = inject(GetAllFromCartUseCase);
  private readonly deleteTaskFromCartUseCase = inject(
    DeleteTaskFromCartUseCase
  );
  private readonly deleteAllTaskFromCartUseCase = inject(
    DeleteAllTaskFromCartUseCase
  );
  private readonly wizardVisualizerService = inject(WizardVisualizerService);
  private destroy$ = new Subject<void>();

  protected readonly cartService = inject(CartService);

  ngOnInit(): void {
    this.getFromCartUseCase
      .execute()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tasks) => {
        this.cartService.setCart(tasks);
        this.cartService.emitCartState(tasks);

        this.cartService
          .whenTaskAdded()
          .pipe(takeUntil(this.destroy$))
          .subscribe((value) => {
            if (value.exists) {
              this.deleteTaskFromCartUseCase
                .execute(value.task.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                  this.cartService.emitCartChanged(value.task.id, 'remove');
                });
              return;
            }

            this.addToCartUseCase
              .execute(value.task)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.cartService.emitCartChanged(value.task.id, 'add');
              });
          });

        this.cartService
          .whenAllTasksCleared()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.deleteAllTaskFromCartUseCase
              .execute()
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.cartService.emitCartState([]);
              });
          });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCartClick(): void {
    this.getFromCartUseCase
      .execute()
      .pipe(first())
      .subscribe((tasks) => {
        if (tasks.length < 1) {
          return;
        }

        if (tasks.length > 1) {
          this.wizardVisualizerService.setTitle('Generador Customizado');
        } else {
          this.wizardVisualizerService.setTitle(tasks[0].name);
        }

        const contractReq = ExecutionContractFactory.create(tasks);
        this.wizardVisualizerService.open(contractReq, tasks);
      });
  }

  protected getStyleClass(): string {
    return this.cartService.getCartCount() > 0
      ? 'rk-toolbar-cart mr-5 rk-toolbar-cart-badge--display'
      : 'rk-toolbar-cart mr-5 rk-toolbar-cart-badge--hide';
  }
}
