import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appLink]',
  standalone: true
})
export class LinkDirective implements OnChanges {
  @Input() color: 'primary' | 'secondary' = 'primary';

  @HostBinding('class') elementClass = `gt_font_color_${this.color} gt_link`;

  ngOnChanges(changes: SimpleChanges) {
    this.elementClass = `gt_font_color_${changes.color.currentValue} gt_link`;
  }
}
