import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { CartRepository } from '@domain/models/cart/gateway/cart-repository';
import { Task } from '@domain/models/task/task';

export class GetAllFromCartUseCase implements UseCase<void, Task[]> {
  constructor(private _cartRepository: CartRepository) {}

  execute(): Observable<Task[]> {
    return this._cartRepository.getTasks();
  }
}
