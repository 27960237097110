import { map, mergeMap, Observable, of } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { CartRepository } from '@domain/models/cart/gateway/cart-repository';
import { Task } from '@domain/models/task/task';
import { Cart } from '@domain/models/cart/cart';

export class AddLegoToCartUseCase implements UseCase<Task, Cart> {
  constructor(private _cartRepository: CartRepository) {}

  execute(task: Task): Observable<Cart> {
    return this._cartRepository.get().pipe(
      map((cart) => {
        if (!cart) {
          return this._cartRepository.create();
        }
        return of();
      }),
      mergeMap(() => this._cartRepository.addTask(task))
    );
  }
}
