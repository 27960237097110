import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { CartRepository } from '@domain/models/cart/gateway/cart-repository';

export class DeleteTaskFromCartUseCase implements UseCase<string, string> {
  constructor(private _cartRepository: CartRepository) {}

  execute(taskId: string): Observable<string> {
    return this._cartRepository.removeTask(taskId);
  }
}
