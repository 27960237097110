import { first, Observable, of } from 'rxjs';
import { AppError } from '@domain/base/app-error';
import { Cart, CART_ID } from '@domain/models/cart/cart';
import { CartErrors } from '@domain/models/cart/cart-errors';
import { CartRepository } from '@domain/models/cart/gateway/cart-repository';
import { Task } from '@domain/models/task/task';

/**
 * Implementation of the CartRepository using local storage.
 */
export class CartRepositoryImpl extends CartRepository {
  get(): Observable<Cart | null> {
    return of(this._getCart()).pipe(first());
  }

  getTasks(): Observable<Task[]> {
    const cart = this._getCart();
    if (!cart) {
      return of([]);
    }
    return of(cart.tasks).pipe(first());
  }

  addTask(task: Task): Observable<Cart> {
    const cart = this._getCart();
    if (!cart) {
      throw new AppError(CartErrors.CART_NOT_FOUND);
    }

    const taskExists = cart.tasks.some((l) => l.id === task.id);
    if (taskExists) {
      throw new AppError(CartErrors.TASK_ALREADY_EXISTS);
    }

    cart.tasks.push(task);
    localStorage.setItem(CART_ID, JSON.stringify(cart));
    return of(cart).pipe(first());
  }

  removeTask(taskId: string): Observable<string> {
    const cart = this._getCart();
    if (!cart) {
      throw new AppError(CartErrors.CART_NOT_FOUND);
    }

    cart.tasks = cart.tasks.filter((task) => task.id !== taskId);
    localStorage.setItem(CART_ID, JSON.stringify(cart));
    return of(taskId).pipe(first());
  }

  clear(): Observable<boolean> {
    localStorage.removeItem(CART_ID);
    return of(true).pipe(first());
  }

  create(): Observable<boolean> {
    localStorage.setItem(CART_ID, JSON.stringify({ tasks: [] }));
    return of(true).pipe(first());
  }

  private _getCart(): Cart | null {
    const storageItem = localStorage.getItem(CART_ID);
    if (!storageItem) {
      return null;
    }

    return JSON.parse(storageItem) as Cart;
  }
}
