import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appExpanderIcon]',
  standalone: true
})
export class ExpanderIconDirective implements OnChanges {
  private baseClass = 'pi pi-angle-down gt_expander';

  @Input() open = false;
  @HostBinding('class') elementClass = this.baseClass;

  ngOnChanges(changes: SimpleChanges) {
    this.elementClass = changes.open.currentValue
      ? this.baseClass + ' expanded'
      : this.baseClass;
  }
}
