import { IdentityRepository } from '@domain/models/identity/gateway/identity-repository';
import { GetIdentityUserUseCase } from '@domain/usecases/identity/get-identity-user';
import { GetUserMenuUseCase } from '@domain/usecases/identity/get-user-menu';
import { IdentityServiceImpl } from '@infra/identity/identity-service';
import { TaskRepositoryFakeImpl } from '@infra/task/task-repository-fake-impl';
import { environment } from 'src/environments/environment';

const getIdentityRepositoryProvider = {
  provide: IdentityRepository,
  useClass: environment.useMocks ? TaskRepositoryFakeImpl : IdentityServiceImpl
};

const getIdentityUserUseCaseFactory = (
  identityRepository: IdentityRepository
) => {
  return new GetIdentityUserUseCase(identityRepository);
};

const getUserMenuUseCaseFactory = (identityRepository: IdentityRepository) => {
  return new GetUserMenuUseCase(identityRepository);
};

const getIdentityUserUseCaseProvider = {
  provide: GetIdentityUserUseCase,
  useFactory: getIdentityUserUseCaseFactory,
  deps: [IdentityRepository]
};

const getUserMenuUseCaseProvider = {
  provide: GetUserMenuUseCase,
  useFactory: getUserMenuUseCaseFactory,
  deps: [IdentityRepository]
};

export const getIdentityDi = [
  getIdentityRepositoryProvider,
  getIdentityUserUseCaseProvider,
  getUserMenuUseCaseProvider
];
