import { ValidationErrors, ValidatorFn } from '@angular/forms';

export function regexValidatorFn(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  return (control) => {
    const value = control.value;
    if (!value && regex.source !== REQUIRED_REGEX) {
      return null;
    }

    const valid = regex.test(value);
    return valid ? null : error;
  };
}

export const REQUIRED_REGEX = '^.+$';
