import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './shared/templates/layout/layout.component';

/**
 * Componente inicial de la aplicación, aquí se carga el router-outlet
 */
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, LayoutComponent],
  template: ` <app-layout></app-layout> `
})
export class AppComponent implements OnInit {
  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute('ng-version');
  }
}
