import { TaskRepository } from '@domain/models/task/gateway/task-repository';
import {
  Task,
  TaskFactory,
  TaskLinks,
  TaskResponse,
  TaskState,
  TaskType
} from '@domain/models/task/task';
import { delay, Observable, of } from 'rxjs';

export class TaskRepositoryFakeImpl extends TaskRepository {
  private DELAY_TIME = 3000;

  private linksStore = [
    {
      url: 'https://www.google.com',
      name: 'Arquitectura de referencia'
    },
    {
      url: 'https://www.facebook.com',
      name: 'Lineamientos patrones de diseño'
    },
    {
      url: 'https://www.twitter.com',
      name: 'Modelo de decisión'
    },
    {
      url: 'https://www.linkedin.com',
      name: 'Estrategia de implementación'
    }
  ];

  private store = [
    {
      id: '1',
      name: 'Solución completa transaccional',
      description:
        'Implementación de las arquitecturas de referencia para frontend, backend e infraestructura comunes en una solución transaccional',
      type: TaskType.Workflow,
      state: TaskState.Active,
      image: 'https://picsum.photos/200/300',
      tags: ['Workflow'],
      disabled: false,
      usefullinks: this.getLinks(3) as TaskLinks[],
      documentationURL: 'https://www.google.com'
    },
    {
      id: '2',
      name: 'Frontend - base',
      description: 'Código base usado en aplicaciones frontend con angular',
      type: TaskType.Lego,
      state: TaskState.Active,
      image: 'https://picsum.photos/200/300',
      tags: ['Lego', 'Angular'],
      disabled: false,
      usefullinks: this.getLinks(1) as TaskLinks[],
      documentationURL: 'https://www.facebook.com'
    },
    {
      id: '3',
      name: 'Infra - bd relacional',
      description:
        'Código de IaC para la creación de una base de datos relacional postgresql',
      type: TaskType.Lego,
      state: TaskState.Active,
      image: 'https://picsum.photos/200/300',
      tags: ['Lego', 'Azure', 'Terraform'],
      disabled: false,
      usefullinks: this.getLinks(4) as TaskLinks[],
      documentationURL: 'https://www.twitter.com'
    },
    {
      id: '4',
      name: 'Devops - repo',
      description: 'Tarea para la solicitud de repositorios en azure repos',
      type: TaskType.Lego,
      state: TaskState.Active,
      image: 'https://picsum.photos/200/300',
      tags: ['Lego', 'Azure'],
      disabled: false,
      usefullinks: this.getLinks(2) as TaskLinks[],
      documentationURL: 'https://www.linkedin.com'
    }
  ];

  getLastAdded(count: number): Observable<Task[]> {
    const legos: Task[] = [];
    for (let i = 1; i <= count; i++) {
      legos.push(this.generateRandomTask(i));
    }

    return of(legos).pipe(delay(this.DELAY_TIME));
  }

  getAll(state: TaskState): Observable<Task[]> {
    if (state == TaskState.All) {
      return of([
        this.generateRandomTaskWithState(1, TaskState.Active),
        this.generateRandomTaskWithState(2, TaskState.Active),
        this.generateRandomTaskWithState(3, TaskState.Active),
        this.generateRandomTaskWithState(4, TaskState.Active),
        this.generateRandomTaskWithState(5, TaskState.Inactive),
        this.generateRandomTaskWithState(6, TaskState.Active),
        this.generateRandomTaskWithState(7, TaskState.Active),
        this.generateRandomTaskWithState(8, TaskState.Active),
        this.generateRandomTaskWithState(9, TaskState.Inactive),
        this.generateRandomTaskWithState(10, TaskState.Active),
        this.generateRandomTaskWithState(11, TaskState.Active),
        this.generateRandomTaskWithState(12, TaskState.Active),
        this.generateRandomTaskWithState(13, TaskState.Active),
        this.generateRandomTaskWithState(14, TaskState.Active),
        this.generateRandomTaskWithState(15, TaskState.Inactive),
        this.generateRandomTaskWithState(16, TaskState.Active),
        this.generateRandomTaskWithState(17, TaskState.Active),
        this.generateRandomTaskWithState(18, TaskState.Active),
        this.generateRandomTaskWithState(19, TaskState.Inactive),
        this.generateRandomTaskWithState(20, TaskState.Active),
        this.generateRandomTaskWithState(21, TaskState.Active),
        this.generateRandomTaskWithState(22, TaskState.Active),
        this.generateRandomTaskWithState(23, TaskState.Active),
        this.generateRandomTaskWithState(24, TaskState.Active),
        this.generateRandomTaskWithState(25, TaskState.Inactive),
        this.generateRandomTaskWithState(26, TaskState.Active),
        this.generateRandomTaskWithState(27, TaskState.Active),
        this.generateRandomTaskWithState(28, TaskState.Active),
        this.generateRandomTaskWithState(29, TaskState.Inactive),
        this.generateRandomTaskWithState(30, TaskState.Active)
      ]).pipe(delay(this.DELAY_TIME));
    } else if (state == TaskState.Active) {
      return of([
        this.generateRandomTaskWithState(1, TaskState.Active),
        this.generateRandomTaskWithState(2, TaskState.Active),
        this.generateRandomTaskWithState(3, TaskState.Active),
        this.generateRandomTaskWithState(4, TaskState.Active),
        this.generateRandomTaskWithState(6, TaskState.Active),
        this.generateRandomTaskWithState(7, TaskState.Active),
        this.generateRandomTaskWithState(8, TaskState.Active),
        this.generateRandomTaskWithState(10, TaskState.Active),
        this.generateRandomTaskWithState(11, TaskState.Active),
        this.generateRandomTaskWithState(12, TaskState.Active),
        this.generateRandomTaskWithState(13, TaskState.Active),
        this.generateRandomTaskWithState(14, TaskState.Active),
        this.generateRandomTaskWithState(15, TaskState.Active),
        this.generateRandomTaskWithState(16, TaskState.Active),
        this.generateRandomTaskWithState(17, TaskState.Active),
        this.generateRandomTaskWithState(18, TaskState.Active),
        this.generateRandomTaskWithState(19, TaskState.Active),
        this.generateRandomTaskWithState(20, TaskState.Active)
      ]).pipe(delay(this.DELAY_TIME));
    } else {
      return of([
        this.generateRandomTaskWithState(5, TaskState.Inactive),
        this.generateRandomTaskWithState(9, TaskState.Inactive)
      ]).pipe(delay(this.DELAY_TIME));
    }
  }

  getAllPaginated(
    limit: number,
    offset: number,
    state: TaskState
  ): Observable<TaskResponse> {
    return of({
      tasks: this.store
        .filter((task) => task.state == state)
        .slice(offset, offset + limit),
      total: this.store.length
    }).pipe(delay(this.DELAY_TIME));
  }

  getLinks(num: number) {
    return this.linksStore.slice(0, num);
  }

  update(task: Task): Observable<void> {
    console.log('Update fake Task', task);
    return of();
  }

  private generateRandomTask(id: number): Task {
    let task = this.store[id - 1];

    if (!task) {
      task = TaskFactory.create(
        id.toString(),
        `Lego ${id}`,
        `Description ${id}`,
        `Image ${id}`,
        id % 2 == 0 ? TaskType.Lego : TaskType.Workflow,
        TaskState.Active,
        [`Tag ${id}`],
        [],
        ''
      );
    }

    return task;
  }

  private generateRandomTaskWithState(id: number, state: TaskState): Task {
    let task = this.store[id - 1];
    const type = id % 2 == 0 ? TaskType.Lego : TaskType.Workflow;
    const tag = id % 2 == 0 ? 'Lego' : 'Workflow';

    if (!task) {
      task = TaskFactory.create(
        id.toString(),
        `Lego ${id}`,
        `Description ${id}`,
        `Image ${id}`,
        type,
        state,
        [tag, `Tag ${id}`],
        [],
        ''
      );
    }

    return task;
  }
}
