import { SidebarRepository } from '@domain/models/sidebar/gateway/sidebar-repository';
import { Subscription } from 'rxjs';

export class OnStatusChangedUseCase {
  constructor(private _sidebarRepository: SidebarRepository) {}

  execute(observer: any): Subscription {
    return this._sidebarRepository.onStatusChanged(observer);
  }
}
