import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ExecutionRequest } from './execution-request';
import { Injectable } from '@angular/core';

/**
 * Servicio encargado de manejar el contrato de ejecución de las tareas seleccionadas
 */
@Injectable({
  providedIn: 'root'
})
export class ExecutionContractService {
  private executionContratSubs = new Subscription();
  private executionContract$ = new BehaviorSubject<ExecutionRequest>(
    {} as ExecutionRequest
  );
  private contract = {} as ExecutionRequest;

  setExecutionContract(contract: Observable<ExecutionRequest>) {
    this.executionContratSubs = contract.subscribe(
      (contract: ExecutionRequest) => {
        this.contract = contract;
        this.executionContract$.next(contract);
      }
    );
  }

  removeStep(taskId: string) {
    const steps = this.contract.steps.filter((task) => task.taskId !== taskId);
    this.contract = { ...this.contract, steps };
    this.executionContract$.next(this.contract);
  }

  getExecutionContract() {
    return this.executionContract$.asObservable();
  }

  closeExecutionContract() {
    this.executionContratSubs.unsubscribe();
    this.executionContract$.next({} as ExecutionRequest);
  }
}
