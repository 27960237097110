import { Routes } from '@angular/router';
import { PreviewComponent } from './executions/pages/preview/preview.component';
import { AuthorizationGuard } from '@infra/security/guard/authorization-guard.service';
import { Error403Component } from './global-error-components/error403/error403.component';

// gen_imports

/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/pages/home.routes').then((route) => route.homeRoutes),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'legos',
    loadChildren: () =>
      import('./tasks/pages/task.routes').then((route) => route.taskRoutes),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/pages/adm.routes').then((route) => route.adminRoutes),
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'preview',
    component: PreviewComponent,
    outlet: 'wizard'
  },
  { path: 'access-denied', component: Error403Component },
  {
    path: '**',
    redirectTo: 'home'
  }
];
