type ExecutionRequest = {
  type: ExecutionType;
  name: string;
  steps: ExecutionInput[];
};

type ExecutionInput = {
  taskId: string;
  name: string;
  description: string;
  order: number;
  inputParams: ExecutionInputParam[];
};

type ExecutionInputParam = {
  inputId: string;
  label: string;
  description: string;
  defaultValue: string;
  type: InputType;
  options: string[];
  validations: InputValidation[];
};

type InputValidation = {
  expReg: string;
  errorMessage: string;
};

enum InputType {
  Text = 'TEXT',
  List = 'LIST',
  Check = 'CHECK',
  number = 'NUMBER',
  area = 'AREA'
}

enum ExecutionType {
  Workflow = 'WORKFLOW',
  Lego = 'LEGO'
}

class ExecutionFactory {
  public static create(
    type: ExecutionType,
    name: string,
    steps: ExecutionInput[]
  ): ExecutionRequest {
    return {
      type,
      name,
      steps
    };
  }
}

export {
  ExecutionRequest,
  ExecutionInput,
  ExecutionInputParam,
  InputType,
  InputValidation,
  ExecutionFactory,
  ExecutionType
};
