import { Observable } from 'rxjs';
import { Task, TaskResponse, TaskState } from '../task';

export abstract class TaskRepository {
  abstract getLastAdded(count: number): Observable<Task[]>;
  abstract getAll(state: TaskState): Observable<Task[]>;
  abstract getAllPaginated(
    limit: number,
    offset: number,
    state: TaskState,
    searchValue: string
  ): Observable<TaskResponse>;
  abstract update(lego: Task): Observable<void>;
}
