import { UseCase } from '@domain/base/use-case';
import { IdentityRepository } from '@domain/models/identity/gateway/identity-repository';
import { MenuHeader } from '@domain/models/identity/menu-header';
import { from, Observable } from 'rxjs';

export class GetUserMenuUseCase implements UseCase<void, MenuHeader> {
  constructor(private _identityRepository: IdentityRepository) {}

  execute(): Observable<MenuHeader> {
    return from(this._identityRepository.getUserMenu());
  }
}
