import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SplashComponent } from '../splash/splash.component';
import { ToolbarComponent } from '../../organisms/toolbar/toolbar.component';
import { SidebarComponent } from '../../organisms/sidebar/sidebar.component';
import { UserMenu } from '@domain/models/auth/user-menu';
import { SetStatusUseCase } from '@domain/usecases/sidebar/set-status-use-case';
import { getIdentityDi } from 'src/app/config/di/identity-di';
import { getSidebarDi } from 'src/app/config/di/sidebar-di';
import { GetIdentityUserUseCase } from '@domain/usecases/identity/get-identity-user';
import { IdentityUser } from '@domain/models/identity/identity-user';
import { Subscription } from 'rxjs';
import { OnStatusChangedUseCase } from '@domain/usecases/sidebar/on-status-changed-use-case';
import { getCartDi } from 'src/app/config/di/cart-di';

/**
 * Componente que representa el layout de la aplicación
 */
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SplashComponent,
    ToolbarComponent,
    SidebarComponent
  ],
  providers: [getIdentityDi, getSidebarDi, getCartDi],
  template: `
    <div
      style="display: flex;
flex-direction: column;
height: 100vh;"
    >
      <app-toolbar></app-toolbar>
      <div
        class="rk-container"
        [ngClass]="{ 'header-menu-active': menuActive }"
      >
        <app-sidebar
          id="rk-aside-responsive"
          class="rk-aside-bar aside"
          *ngIf="userMenu"
          (expanded)="isExpanded = $event"
          [userMenu]="userMenu"
          [ngClass]="{ isExpanded: isExpanded }"
        ></app-sidebar>

        <div class="rk-app-content-wrapper" (click)="expandedHome()">
          <router-outlet></router-outlet>
        </div>

        <div class="layout-mask"></div>
      </div>
      <app-splash
        class="fixed w-full top-0 app-splash--background z-5"
      ></app-splash>
    </div>
  `,
  styles: []
})
export class LayoutComponent implements OnInit, OnDestroy {
  protected userMenu?: UserMenu;
  protected user?: IdentityUser;
  public isExpanded = false;
  public menuActive = false;
  private sidebarSuscription?: Subscription = undefined;

  private readonly getIdentityUserUseCase = inject(GetIdentityUserUseCase);
  private readonly setStatusUseCase = inject(SetStatusUseCase);
  private readonly onStatusChangedUseCase = inject(OnStatusChangedUseCase);

  ngOnInit(): void {
    this.sidebarSuscription = this.onStatusChangedUseCase.execute(
      (status: boolean) => (this.menuActive = status)
    );
    const identityUser$ = this.getIdentityUserUseCase.execute();
    identityUser$.subscribe((userInfo) => {
      this.user = userInfo;
      this.userMenu = userInfo.menu;
    });
  }

  expandedHome() {
    this.setStatusUseCase.execute(false);
  }

  playSidebar() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnDestroy() {
    this.sidebarSuscription?.unsubscribe();
  }
}
