import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */

export const securityInterceptRequestService: HttpInterceptorFn = (
  req,
  next
) => {
  const router: Router = inject(Router);
  const location: Location = inject(Location);

  let headers = new HttpHeaders({ 'X-APP-RELAYSTATE': location.path() });
  headers = headers.append('Content-Type', 'application/json');

  const reqWithHeaders = req.clone({ withCredentials: true, headers });

  return next(reqWithHeaders).pipe(
    catchError((err) => {
      switch (err.status) {
        case 401:
          //  Presenta la pagina de login desde seus
          document.write(err.error);
          return throwError(() => err);
        case 403:
          router.navigate(['/access-denied']);
          return throwError(() => err);
        default:
          return throwError(() => err);
      }
    })
  );
};
