import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { Task } from '@domain/models/task/task';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartNotifier = new Subject<Task>();
  private cartWithTasksNotifier = new BehaviorSubject<Task[]>([]);
  private cartActionNotifier = new BehaviorSubject<{
    id: string;
    action: 'add' | 'remove' | 'none';
  }>({ id: '', action: 'none' });

  private tasksInCart: Task[] = [];
  private clearAllTasksNotifier = new Subject<void>();

  setCart(tasks: Task[]) {
    this.tasksInCart = tasks;
  }

  getCart() {
    return this.tasksInCart;
  }

  getCartCount() {
    return this.tasksInCart.length;
  }

  listenCartState() {
    return this.cartWithTasksNotifier.asObservable();
  }

  emitCartState(tasks: Task[]) {
    this.cartWithTasksNotifier.next(tasks);
  }

  whenCartChanged() {
    return this.cartActionNotifier.asObservable();
  }

  emitCartChanged(id: string, action: 'add' | 'remove') {
    this.cartActionNotifier.next({ id, action });
  }

  whenTaskAdded() {
    return this.cartNotifier.asObservable().pipe(
      map((task) => {
        const exists = this.tasksInCart.find((l) => l.id === task.id);
        this.tasksInCart = exists
          ? this.tasksInCart.filter((l) => l.id !== task.id)
          : [...this.tasksInCart, task];
        return { task: task, exists };
      })
    );
  }

  clearAllTasks() {
    this.tasksInCart = [];
    this.clearAllTasksNotifier.next();
  }

  whenAllTasksCleared() {
    return this.clearAllTasksNotifier.asObservable();
  }

  addTaskToCart(cart: Task) {
    this.cartNotifier.next(cart);
  }
}
