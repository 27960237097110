import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { PreviewInputsViewModel } from '../wizard/wizard.viewmodel';
import { Task } from '@domain/models/task/task';
import { CartService } from '@domain/services/cart.service';
import {
  ExecutionInputParam,
  InputType
} from '@domain/models/execution/execution-request';
import { ExecutionContractService } from '@domain/models/execution/execution-contract-service';
import { TextContentAtmDirective } from '@view/shared/atoms/fields/text-content.directive';
import { IconDirective } from '@view/shared/atoms/icons';
import { FormNumberItemaMolComponent } from '@view/shared/molecules/form-number-item/form-number-item.component';
import { FormTextItemMolComponent } from '@view/shared/molecules/form-text-item/form-text-item.component';
import { ListItemMolComponent } from '@view/shared/molecules/list-item/list-item.component';
import { WizardService } from '@domain/services/wizard.service';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    DividerModule,
    ButtonModule,
    IconDirective,
    TextContentAtmDirective,
    ListItemMolComponent,
    FormTextItemMolComponent,
    FormNumberItemaMolComponent
  ],
  template: `
    <div class="flex flex-column">
      <div class="flex">
        <div class="flex-1 ml-3">
          <div class="mb-2" appTextContentAtm>Campos básicos</div>
          <form [formGroup]="commonForm">
            <div
              class="mb-4"
              *ngFor="let input of formInputs"
              [ngSwitch]="input.type"
            >
              <app-form-text-item-mol
                *ngSwitchCase="'TEXT'"
                class="flex"
                [parentForm]="commonForm"
                [input]="input"
              ></app-form-text-item-mol>
              <app-form-number-item-mol
                *ngSwitchCase="'NUMBER'"
                class="flex"
                [parentForm]="commonForm"
                [input]="input"
              ></app-form-number-item-mol>
            </div>
          </form>
        </div>
        <div class="flex-1 mr-3">
          <div class="mb-2" appTextContentAtm>
            Se van a generar los siguientes LEGOS:
          </div>
          <ul class="list-none p-0 m-0">
            <li
              class="flex justify-content-between mb-3"
              *ngFor="let preview of previewTasks"
            >
              <app-list-item-mol
                iconId="box"
                [id]="preview.id"
                [text]="preview.name"
                [link]="preview.documentationURL"
                [canDelete]="true"
                class="w-full"
                (deleted)="onItemDeleted($event)"
              >
              </app-list-item-mol>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <p-divider styleClass="m-2"></p-divider>
        <div class="flex justify-content-between">
          <span></span>
          <p-button
            [disabled]="!isFormValid()"
            icon="pi pi-check"
            [ngClass]="{
              'rk-button--primary': isFormValid()
            }"
            (click)="onStart()"
            label="Iniciar"
          ></p-button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .rk-form-group-item {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
      }
    `
  ]
})
export class PreviewComponent implements OnInit, OnDestroy {
  @Output()
  readonly started = new EventEmitter<PreviewInputsViewModel>();
  protected readonly wizardService = inject(WizardService);
  protected readonly executionContractService = inject(
    ExecutionContractService
  );
  protected readonly cartService = inject(CartService);

  protected commonForm = new FormGroup({
    projectName: new FormControl('', [Validators.required]),
    applicationName: new FormControl('', [Validators.required]),
    apmId: new FormControl('', [Validators.required])
  });

  protected formInputs: ExecutionInputParam[] = this.configureFormInputs();
  protected previewTasks: Task[] = [];

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.wizardService
      .watchRestartExecution()
      .pipe(takeUntil(this.destroy$))
      .subscribe((restart) => {
        if (restart) {
          this.commonForm.reset();
        }
      });

    this.wizardService
      .getPreviewTasks()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tasks) => {
        this.previewTasks = tasks;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStart() {
    if (!this.isFormValid()) {
      return;
    }

    const formData = {
      projectName: this.commonForm.get('projectName')?.value ?? '',
      applicationName: this.commonForm.get('applicationName')?.value ?? '',
      apmId: this.commonForm.get('apmId')?.value ?? '',
      tasks: this.previewTasks
    };

    this.started.emit(formData);
  }

  onItemDeleted(id: string) {
    const taskToDelete = this.previewTasks.find((l) => l.id === id);
    if (!taskToDelete) {
      return;
    }

    this.previewTasks = this.previewTasks.filter((l) => l.id !== id);
    this.cartService.addTaskToCart(taskToDelete);
    this.executionContractService.removeStep(id);
  }

  protected isFormValid() {
    return this.previewTasks.length > 0 && this.commonForm.valid;
  }

  private configureFormInputs() {
    return [
      {
        inputId: 'projectName',
        type: InputType.Text,
        label: 'Nombre del proyecto',
        description:
          'Nombre del proyecto, en DDD se refiere al dominio asociado al problem space que se trata de resolver',
        defaultValue: '',
        options: [],
        validations: [
          {
            expReg: '^.+$',
            errorMessage: 'El nombre del proyecto es requerido'
          }
        ]
      },
      {
        inputId: 'applicationName',
        type: InputType.Text,
        label: 'Nombre de la aplicación',
        description:
          'Nombre de la aplicación, en DDD se refiere al subdominio o contexto delimitado identificado en el dominio',
        defaultValue: '',
        options: [],
        validations: [
          {
            expReg: '^.+$',
            errorMessage: 'El nombre de la aplicación es requerida'
          }
        ]
      },
      {
        inputId: 'apmId',
        type: InputType.number,
        label: 'Id del APM',
        description:
          'Identificador que se obtiene desde el portafolio de aplicaciones',
        defaultValue: '',
        options: [],
        validations: [
          {
            expReg: '^.+$',
            errorMessage: 'El id del APM es requerido'
          }
        ]
      }
    ];
  }
}
