import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appTextContentAtm]',
  standalone: true
})
export class TextContentAtmDirective implements OnChanges {
  @Input() size: 'normal' | 'small' | 'caption' = 'normal';

  @HostBinding('class')
  elementClass = this.setClassValue();

  ngOnChanges(changes: SimpleChanges) {
    this.size = changes.size ? changes.size.currentValue : 'normal';
    this.elementClass = this.setClassValue();
  }

  setClassValue() {
    return `rk-body--${this.size}`;
  }
}
