import { Observable } from 'rxjs';
import { Task } from '@domain/models/task/task';
import { Cart } from '../cart';

export abstract class CartRepository {
  abstract get(): Observable<Cart | null>;
  abstract getTasks(): Observable<Task[]>;
  abstract addTask(task: Task): Observable<Cart>;
  abstract removeTask(taskId: string): Observable<string>;
  abstract clear(): Observable<boolean>;
  abstract create(): Observable<boolean>;
}
