import { UserMenuItem } from '@domain/models/auth/user-menu';
import { SidebarRepository } from '@domain/models/sidebar/gateway/sidebar-repository';

export class InactivateAllItemsUseCase {
  constructor(private _sidebarRepository: SidebarRepository) {}

  execute(items: Array<UserMenuItem>): void {
    this._sidebarRepository.inactivateAllItems(items);
  }
}
