import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { IdentityUser } from '@domain/models/identity/identity-user';
import { IdentityRepository } from '@domain/models/identity/gateway/identity-repository';

export class GetIdentityUserUseCase implements UseCase<void, IdentityUser> {
  constructor(private _identityRepository: IdentityRepository) {}
  execute(): Observable<IdentityUser> {
    return this._identityRepository.getUserInfo();
  }
}
