import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenu, UserMenuItem } from '@domain/models/auth/user-menu';
import { SidebarItemComponent } from '@view/shared/molecules/sidebar-item/sidebar-item.component';
import { HttpRouterClient } from '@infra/helpers/http-router-service';
import { InactivateAllItemsUseCase } from '@domain/usecases/sidebar/inactivate-all-items-use-case';
import { getSidebarDi } from 'src/app/config/di/sidebar-di';

@Component({
  selector: 'app-sidebar[userMenu]',
  standalone: true,
  imports: [CommonModule, SidebarItemComponent],
  providers: [getSidebarDi],
  template: `
    <aside
      class="rk-sidebar"
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseLeave()"
      [ngClass]="{ isExpanded: isExpanded }"
    >
      <div class="p-fluid grid" style="margin-left: 0.6vh">
        <div class="col-12 col-md-4 input" style="width: 70px;">
          <div
            id="rk-play"
            [ngClass]="{ isExpanded: isExpanded }"
            (click)="playSidebar()"
          >
            <span class="pi pi-play"></span>
          </div>
        </div>
      </div>
      <div class="p-fluid grid" style="margin-left: 0.3vh">
        <div class="col-12 col-md-4 input" style="width: 70px;">
          <a>
            <img
              class="rk-image-line"
              loading="lazy"
              src="./../../../assets/images/horizontal_line.png"
              alt="horizontal_line"
            />
          </a>
        </div>
      </div>
      <ul class="rk-list">
        <app-sidebar-item
          *ngFor="let menuItem of userMenu.menuItems"
          [item]="menuItem"
          [isExpanded]="isExpanded"
          (expanded)="playSidebar($event)"
        ></app-sidebar-item>
      </ul>
      <div id="p-botton-img" class="p-fluid grid">
        <div class="col-12 col-md-4 input" style="width: 50px;">
          <a>
            <img
              class="rk-image-line"
              loading="lazy"
              src="./../../../assets/images/horizontal_line.png"
              alt="horizontal_line"
            />
          </a>
        </div>
      </div>
    </aside>
  `
})
export class SidebarComponent implements OnInit {
  @Input() userMenu!: UserMenu;
  @Output() expanded = new EventEmitter<boolean>();
  isExpanded = false;
  timer: any = null;
  waitingInSeconds = 2100;
  clickPlay!: boolean;
  clickSubMenuOrClick!: boolean;
  enterUserFirst!: boolean;

  private readonly inactivateAllItemsUseCase = inject(
    InactivateAllItemsUseCase
  );
  private readonly appRouter = inject(HttpRouterClient);

  ngOnInit(): void {
    this.isExpanded = false;
    this.enterUserFirst = false;
    this.clickPlay = false;
    this.clickSubMenuOrClick = false;
    this.toggleSubmenu(this.userMenu.menuItems[0]);
    this.setMenuAsActive(this.appRouter.getCurrentUrl());
  }

  expandCollapseAside() {
    this.expanded.emit(this.isExpanded);
  }

  resetSidebar() {
    this.clickSubMenuOrClick = false;
    this.isExpanded = false;
    this.clickPlay = false;

    this.expandCollapseAside();
  }

  closedAutomatic() {
    if (this.isExpanded && this.clickPlay) {
      this.resetSidebar();
    }
  }

  mouseEnter() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  mouseLeave() {
    this.timer = setTimeout(() => {
      this.closedAutomatic();
    }, this.waitingInSeconds);
  }

  /**
   * Marca los items que deben ser activados del menu cuando se carga la pagina
   *
   * @param url url del router
   */
  setMenuAsActive(url: string): void {
    if (!this.userMenu) {
      return;
    }

    this.userMenu.menuItems.forEach((item) =>
      this.markIfIsActive(item, '/', url)
    );
  }

  /**
   * marca de forma recursiva los items activos cuando se refresca la pagina, para que en el menú salgan seleccionados
   *
   * @param item Item a comprobar
   * @param baseUrl url base
   * @param url complemento de la url
   */
  markIfIsActive(item: UserMenuItem, baseUrl: string, url: string): void {
    const shouldBeMarked =
      baseUrl.concat(item.contextPath) === url ||
      url.indexOf(baseUrl.concat(item.contextPath)) === 0;

    if (item.children && item.children.length > 0) {
      item.children.forEach((childItem) =>
        this.markIfIsActive(childItem, '/', url)
      );
    }

    item.active = shouldBeMarked;
  }

  /**
   * Al darle click en el play activa funcionalidad de expander o contraer
   */
  playSidebar(isExpanded?: boolean) {
    this.clickSubMenuOrClick = true;
    this.isExpanded = isExpanded ?? !this.isExpanded;
    this.clickPlay = this.isExpanded;
    this.expandCollapseAside();
  }

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   *
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: UserMenuItem): void {
    // desactivamos todos los menus
    this.inactivateAllItemsUseCase.execute(this.userMenu?.menuItems || []);

    const isActive = menu.active;

    menu.active = !isActive;

    this.clickSubMenuOrClick = false;

    if (!this.clickPlay) {
      if (!this.enterUserFirst) {
        this.expandCollapseAside();
        this.enterUserFirst = !this.enterUserFirst;
      } else {
        this.playSidebar();
        this.clickSubMenuOrClick = false;
      }
    }
  }
}
