import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-splash',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      *ngIf="canShow"
      [ngClass]="{
        'fadeout animation-duration-500 animation-iteration-1': startAnimation
      }"
      class="flex h-screen justify-content-center align-items-center"
    >
      <div class="w-30rem h-30rem text-center">
        <img
          class="app-splash--dim"
          src="assets/images/splash.svg"
          alt="splash"
        />
        <span
          class="rk-headline--h4 fadeout animation-duration-2000 animation-iteration-infinite"
          >Cargando...</span
        >
      </div>
    </div>
  `,
  styles: []
})
export class SplashComponent implements OnInit {
  protected canShow = true;
  protected startAnimation = false;

  private timeToHide = 4000;
  private timeToStartAnimation = 3500;

  ngOnInit() {
    setTimeout(() => {
      this.startAnimation = true;
    }, this.timeToStartAnimation);

    setTimeout(() => {
      this.canShow = false;
    }, this.timeToHide);
  }
}
