import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { RouterModule } from '@angular/router';
import { IdentityUser } from '@domain/models/identity/identity-user';

@Component({
  selector: 'app-admin-toolbar',
  standalone: true,
  imports: [CommonModule, AvatarModule, RouterModule],
  template: `
    <div class="flex pt-3 mr-4">
      <div *ngIf="user" class="flex flex-column">
        <button class="rk-toolbar-user" (click)="toggleMenu()">
          <div class="rk-body--normal rk-text--capitalize">
            <span>{{ user.fullName }}</span>
          </div>
          <img
            class="rk-toolbar-user-image"
            alt="user-icon"
            src="assets/images/user.png"
          />
          <em class="pi pi-angle-down"></em>
        </button>
        <ul
          class="rt-floatmenu-user"
          [ngClass]="{ 'profile-menu_active': userMenuActive }"
        >
          <li class="rt-floatmenu-user-access">
            <span
              >Último ingreso:
              {{ user.lastAccess | date: 'dd/MM/yyyy, h:mm a' }}</span
            >
          </li>
          <li class="rt-floatmenu-user-accions">
            <a
              href="https://micuenta.suranet.com/"
              rel="noopener"
              target="_blank"
            >
              <em class="pi pi-user mr-2"></em>
              <span>Mi cuenta</span>
            </a>
          </li>
          <li class="rt-floatmenu-user-accions">
            <a href="{{ user.fullUrlLogout }}">
              <em class="pi pi-power-off mr-2"></em>
              <span>Cerrar Sesion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  `,
  styles: []
})
export class AdminToolbarComponent {
  @Input() user?: IdentityUser;

  userMenuActive = false;

  toggleMenu(): void {
    this.userMenuActive = !this.userMenuActive;
  }
}
