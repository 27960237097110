import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { RouterModule } from '@angular/router';
import { UserMenuItem } from '@domain/models/auth/user-menu';
import { IconDirective } from '@view/shared/atoms/icons';

@Component({
  selector: 'app-sidebar-sub-item',
  standalone: true,
  imports: [CommonModule, RouterModule, IconDirective],
  animations: [
    trigger('anim', [
      state(
        'false',
        style({
          height: '0',
          padding: '0'
        })
      ),
      state(
        'true',
        style({
          height: '*'
        })
      ),
      transition('false => true', animate('250ms ease-in')),
      transition('true => false', animate('250ms ease-out'))
    ])
  ],
  template: `
    <ul class="rk-sidebar-submenu" [@anim]="visible">
      <li
        *ngFor="let menu of itemsMenu"
        class="rk-sidebar-item"
        [ngClass]="{ gt_sidebar_submenu_item: menu.active }"
      >
        <a
          class="rk-sidebar-item-link pl-5"
          [routerLink]="
            menu.children && menu.children.length > 0
              ? null
              : '/'.concat(menu.contextPath)
          "
        >
          <em appIcon [icon]="menu.image" class="mr-2"></em>
          <span class="rk-text-submenu rk-headline--sub-one">{{
            menu.name
          }}</span>
        </a>
      </li>
    </ul>
  `,
  styles: []
})
export class SidebarSubItemComponent {
  /**
   * items que deben ser renderizados
   */
  @Input()
  itemsMenu: Array<UserMenuItem> = [];

  /**
   * indica si se debe mostrar el submenu, esto ejecuta la animación
   */
  @Input()
  visible = false;

  /**
   * path del menu padre, este se usa para completar el enrutamiento
   */
  @Input() basePath = '';

  /**
   * valor para aplicar un padding izquierdo a un subitem, valores validos:
   * 10: aplica padding-left 10
   * 20: aplica padding-left 20
   * 30: aplica padding-left 30
   * 40: aplica padding-left 40
   * 50: aplica padding-left 50
   */
  @Input() paddingLeft = 10;
}
