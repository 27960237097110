import { Observable } from 'rxjs';
import { UseCase } from '@domain/base/use-case';
import { CartRepository } from '@domain/models/cart/gateway/cart-repository';

export class DeleteAllTaskFromCartUseCase implements UseCase<void, boolean> {
  constructor(private _cartRepository: CartRepository) {}

  execute(): Observable<boolean> {
    return this._cartRepository.clear();
  }
}
